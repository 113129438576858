<template>

  <div>

    <vue-element-loading
      style="min-height: 500px;"
      :active="carregamentoApi"
      spinner="bar-fade-scale"
      text="Carregando Campanha"
    />

    <section
      id="knowledge-base-content"
      class="pb-5"
    >

      <b-row>

        <b-col
          xl="4"
          cols="12"
        >

          <v-lazy-image 
            fluid 
            class="mb-2 img-thumbnail"
            :src="campanha.arquivo || require('@/assets/images/placeholder/campanhas.gif')"
            :src-placeholder="require(`@/assets/images/placeholder/campanhas.gif`)"
          />

        </b-col>

        <b-col
          xl="8"
          cols="12"
        >

          <b-card
            no-body
          >
            <b-card-body 
              class="ecommerce-card mt-1 ml-1"
            >

              <b-badge
                variant="secondary"
              >
              Válida de {{ campanha.inicio }} até {{ campanha.fim }}
              </b-badge>
              <h3 class="mt-1">{{ campanha.nome }}</h3>
              <p>{{ campanha.descricao }}</p>
            
            </b-card-body>
            
            <!-- Action Buttons -->
            <div class="item-options text-center">
              <b-button
                variant="primary"
                class="btn-cart move-cart"
                style="border-radius: 0px 0px 8px 8px !important;width: 100%;"
                @click="modalParticipar"
              >
                <span>Enviar meus hits</span>
                <b-icon
                  icon="arrow-right-short"
                  class="mr-50"
                />
              </b-button>
            </div>

          </b-card>

          <b-card
            title="Meus Hits Enviados"
          >
            <!-- body -->
            <b-card-body>

              <div 
                v-if="!hitsParticipantes.length"
                class="d-flex justify-content-center align-items-center text-center pt-2 pb-2" 
                style="height: 100%;"
              >
                <div>
                  <feather-icon
                    size="30"
                    icon="MusicIcon"
                  />
                  <h5 class="mt-1">Você ainda não enviou nenhum hit para essa campanha</h5>
                  <small>Os seus hits participantes dessa campanha serão exibidos aqui</small>
                </div>
              </div>

              <div
                v-for="hit in hitsParticipantes"
                :key="hit.id"
                class="browser-states"
              >
              
                <b-media no-body>
                  <b-media-aside class="mr-1">
                    <b-avatar
                      rounded
                      size="42"
                      class="mb-1"
                      variant="primary"
                    >
                      <feather-icon
                        size="18"
                        icon="MusicIcon"
                      />
                    </b-avatar>
                  </b-media-aside>
                  <b-media-body>
                    <h6 class="transaction-title">
                      {{ hit.nomeComposicao }} 
                    </h6>
                    <b-badge
                      pill
                      variant="light-secondary"
                      class="text-capitalize"
                      style="margin-right: 5px;"
                      v-for="tema in hit.listaTemas"
                    >
                      {{ tema.nome }}
                    </b-badge>  
                  </b-media-body>
                </b-media>
              </div>

            </b-card-body>
            <!--/ body -->

          </b-card>

        </b-col>

      </b-row>

    </section>

    <!-- Modal de Negociação -->

    <b-modal
      id="modal-participar"
      ref="modalParticipar"
      ok-only
      centered
      no-close-on-backdrop
      size="sm"
      title="Participar da Campanha"
    >

      <template v-slot:modal-footer>
        <b-button
          block
          variant="primary"
          :disabled="invalid || respostaApi"
          @click="enviarHitCampanha"
        >
          Enviar Hit
          <b-spinner
            v-if="respostaApi"
            small
            label="Carregando"
          />
        </b-button>
      </template>

      <b-alert
        variant="primary"
        show
        class="mt-1"
      >
        <div class="alert-body">
          <span>Selecione <b>o seu hit</b> para enviar para essa campanha</span>
        </div>
      </b-alert>


      <validation-observer
        #default="{invalid}"
        ref="negociacaoForm"
      >

        <!-- Form -->
        <b-form
          class="mt-2"
          @submit.prevent="enviarHitCampanha"
        >

          <b-row>

            <b-col>

              <!-- Estilos -->
              <b-form-group
                label="Tipo de Negociação"
                label-for="hitId"
              >
                <validation-provider
                  #default="{ errors }"
                  name="hitId"
                  rules="required"
                >

                  <v-select
                    v-model="hitSelecionado"
                    label="nomeComposicao"
                    :options="hitsCompositor"
                    :filterable="true"
                    @search="pesquisarComposicao"
                    placeholder="Digite o nome da composição e selecione"
                  >
                  <template v-slot:no-options>Nenhum hit encontrado com esse nome</template>
                  </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
              <!-- Estilos -->

            </b-col>

          </b-row>

        </b-form>
      </validation-observer>

    </b-modal>

  </div>
</template>

<script>
import router from '@/router'
import {
  BSpinner, BFormGroup, BAlert, BModal, BContainer, BRow, BCol, BCard, BCardTitle, BCardHeader, BCardBody, BForm, BFormInvalidFeedback, BInputGroup, BFormInput, BCardText, BInputGroupPrepend, IconsPlugin, BBadge, BButton, BMediaBody, BMedia, BMediaAside, BAvatar, BDropdown, BDropdownItem, BImg,
} from 'bootstrap-vue'

import { ValidationProvider, ValidationObserver, localize } from 'vee-validate'

import useJwt from '@/auth/jwt/useJwt'

// CARREGANDO DE PÁGINA PARA API
import VueElementLoading from 'vue-element-loading'

import moment from 'moment'

import axios from 'axios'

import vSelect from 'vue-select'

import { required, email } from '@validations'

import VLazyImage from 'v-lazy-image/v2';


localize({
  en: {
    messages: {
      // generic rule messages...
    },
    fields: {
      hitId: {
        required: 'O nome do hit é obrigatório.',
      },
    },
  },
})

export default {
  components: {
    BSpinner,
    BFormGroup,
    BAlert,
    BModal,
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardTitle,
    BCardHeader,
    BCardBody,
    BCardText,
    BForm,
    BFormInvalidFeedback,
    BInputGroup,
    BInputGroupPrepend,
    IconsPlugin,
    BFormInput,
    BBadge,
    BButton,
    BMediaBody,
    BMedia,
    BMediaAside,
    BAvatar,
    BDropdown,
    BDropdownItem,
    VueElementLoading,
    moment,
    BImg,
    vSelect,

     // Form Validation
     ValidationProvider,
    ValidationObserver,

    VLazyImage,

  },
  data() {
    return {
      invalid: false,
      campanha: {},
      carregamentoApi: false,
      hitsCompositor: [],
      hitSelecionado: null,
      respostaApi: null,
      minLetrasPesquisa: 3,
      hitsParticipantes: [],

    }
  },
  computed: {

    // this.hitsLista();

  },
  created() {
    this.campanhaId()
    this.hitCompositor()
    this.hitParticipacao()
  },

  methods: {

    // STATUS

    statusAprovacaoVariant(status) {
      if (status === 'EM_ANALISE') return 'warning'
      if (status === 'APROVADO') return 'success'
      if (status === 'REPROVADO_GERAL') return 'danger'
      if (status === 'REPROVADO_INFORMAÇÕES') return 'danger'
      if (status === 'REPROVADO_GUIA') return 'danger'
      if (status === 'PENDENTE_PAGAMENTO') return 'warning'
      if (status === 'AGUARDANDO_GUIA') return 'warning'
      return 'warning'
    },

    statusAprovacaoLabel(status) {
      if (status === 'EM_ANALISE') return 'Em análise'
      if (status === 'APROVADO') return 'Aprovado'
      if (status === 'REPROVADO_GERAL') return 'Reprovado'
      if (status === 'REPROVADO_INFORMACOES') return 'Reprovado'
      if (status === 'REPROVADO_GUIA') return 'Reprovado'
      if (status === 'PENDENTE_PAGAMENTO') return 'Pendente Pagamento'
      if (status === 'AGUARDANDO_GUIA') return 'Aguardando Guia'
      return 'Em análise'
    },

    pesquisarComposicao(searchTerm) {

      if (searchTerm.length < this.minLetrasPesquisa) {
        return
      }

      useJwt.hitCompositorLista({
        nomeComposicao: searchTerm,
        statusAprovacao: 1,
        page: 0,
        size: 8,
        sort: 'ASC',
      })
      .then(response => {

        this.hitsCompositor = response.data.content

      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        // this.carregamentoApi = false
      })

    },

    enviarHitCampanha() {
      this.$refs.negociacaoForm.validate().then(success => {
        if (success) {
          this.respostaApi = true

          useJwt.hitCampanhaEnviar({
            hitId: this.hitSelecionado.id,
            campanhaId: router.currentRoute.params.id
          })
            .then(response => {
              this.$swal({
                title: 'Hit Enviado!',
                text: 'O seu hit foi enviado à campanha.',
                icon: 'success',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,

              })
            })
            .catch(error => {
              this.$swal({
                title: 'Erro!',
                text: error.response.data.message,
                icon: 'error',
                customClass: {
                  confirmButton: 'btn btn-primary',
                },
                buttonsStyling: false,
              })
            })
            .finally(() => {
              this.respostaApi = false
              this.$refs.modalParticipar.hide()
              this.hitParticipacao()
            })
        }
      })
    },

    // MODAL NEGOCIAR

    modalParticipar() {
      this.$refs.modalParticipar.show()
    },

    formatarData(date) {
      return moment(date).format('DD/MM/YYYY HH:mm')
    },

    campanhaId() {
      this.carregamentoApi = true

      useJwt.campanhaCompositorId({
        id: router.currentRoute.params.id,
      })
      .then(response => {
        //console.log(response.data)

        this.campanha = response.data

      })
      .catch(error => {
        console.log(error)
      })
      .finally(() => {
        this.carregamentoApi = false
      })
    },

    hitCompositor() {
      this.carregamentoApi = true

      useJwt.hitCompositorLista({
        statusAprovacao: 1,
        page: 0,
        size: 8,
        sort: 'ASC',
      })
        .then(response => {
          //console.log(response.data.content)
          if (response.data.content.length) {
            this.hitsCompositor = response.data.content
          } else {
            this.hitsCompositor = {}
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.carregamentoApi = false
        })
    },

    hitParticipacao() {
      this.carregamentoApi = true

      useJwt.hitCompositorCampanhaParticipacao({
        campanhaId: router.currentRoute.params.id
      })
        .then(response => {
          //console.log(response.data.content)
          if (response.data.content.length) {
            this.hitsParticipantes = response.data.content
          } else {
            this.hitsParticipantes = {}
          }
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.carregamentoApi = false
        })
    },

    validationNegociarForm() {
      // console.log( Number(this.hitValorLiberacao) );return false;

      return new Promise((resolve, reject) => {
        this.$refs.negociacaoForm.validate().then(success => {
          if (success) {
            resolve(true)
          } else {
            reject()
          }
        })
      })
    },

  },
}
</script>

  <style lang="scss">
  @import '@core/scss/vue/pages/page-knowledge-base.scss';
  @import "@core/scss/base/pages/app-ecommerce.scss";


  .card-campanha .card-title{
    font-size: 14px;
  }

  .card-campanha .card-subtitle {
    font-size: 12px;
  }


  </style>
